@import "assets/styles/vars";

.timePicker {
  position: relative;
  margin: 24px 0;
  color: $text-primary;
  font-weight: 700;
  font-size: 56px;

  &::before {
    position: absolute;
    top: 60px;
    left: calc(50vw - 22px);
    width: 100%;
    height: auto;
    content: ":";
  }
}
