@import "assets/styles/vars";

.card {
  display: flex;
  flex-direction: column;
  color: $text-primary;
  background-color: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
}

.debt {
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid $border-light;
}

.sum {
  font-size: 80px;
  font-weight: 500;
  line-height: 98px;
  letter-spacing: 2px;
  margin-top: 12px;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.value {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
