@import "assets/styles/vars";

.checkbox {
  display: flex;
  align-items: flex-start;
}

.checkboxInput {
  visibility: hidden;
  position: absolute;
}

.checkboxWrapper {
  display: flex;
  position: relative;
}

.checkboxLabel {
  position: relative;
  background-color: $bg-secondary;
  margin-right: 16px;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.checkboxText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $dark-blue;

  & > a {
    text-decoration: underline;
    color: $dark-blue;
  }
}

.checkboxChecked {
  &::before {
    top: 5px;
    left: 3px;
    position: absolute;
    content: '';
    width: 18px;
    height: 14px;
    background-image: url("./img/ok.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.error {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $error;
}
