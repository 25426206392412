.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .inner {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: stretch;
    height: 200px;
  }

  .img {
    margin: 0 auto;
    min-height: 186px;
    position: relative;
    width: 100%;

    &::before {
      background: linear-gradient(180deg, #000, transparent);
      content: "";
      height: 96px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}

.logo {
  height: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 11px;
  width: 110px;
  z-index: 3;
}

@keyframes fromright {
  0% {
    right: -100%;
  }

  49% {
    right: 0;
    z-index: 1;
  }

  50% {
    right: 0;
    z-index: -1;
  }

  100% {
    right: 0;
    z-index: -1;
  }
}
