@import "assets/styles/vars";

.title {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $text-gray;
  margin-bottom: 6px;
}

.tabs {
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
}

.tab {
  width: 100%;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: $bg-primary;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $text-secondary;
  letter-spacing: .5px;
  text-align: center;

  &[data-active="true"] {
    color: $white;
    background-color: $green;
  }
}
