.wrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding: 16px;
}

.submitting {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: auto;
  margin-right: 16px;

  & > div {
    width: 24px;
    height: 24px;

    & > div {
      margin: 0;
      width: 24px;
      height: 24px;
      border: 4px solid #FFF;
      border-color: #FFF transparent transparent;
    }
  }
}
