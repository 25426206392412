@import "assets/styles/vars";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contacts {
  color: $text-primary;
  background: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  padding: 16px;
  margin-bottom: 24px;
}

.subTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $text-primary;
  margin-bottom: 6px;
}

.subText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $text-primary;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: $text-primary;
}

.comment {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-primary;
  margin-top: 6px;
  margin-bottom: 6px;
}

.client {
  background-color: $bg-secondary;
  padding: 16px;
  border-radius: 12px;
  box-shadow: $shadow-light;
}

.row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $text-primary;

  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}

.btn {
  position: sticky;
  bottom: 0;
  z-index: 2;
  width: calc(100% + 32px);
  margin-left: -16px;

  button {
    color: $white;
    background-color: $green-light;
  }

  &[data-valid="true"] button {
    background-color: $green;
  }
}
