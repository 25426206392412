@import "assets/styles/vars";

.container {
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -5px 50px rgba(0,0,0,.75);
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  margin-top: auto;
  position: relative;
  z-index: 2;
  background: linear-gradient(180deg,#f5f5f5,#e4e4e4);
}

.error {
  padding: 16px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: $text-primary;

  & > div {
    position: absolute;
    bottom: 32px;
    width: calc(100% - 32px);
  }

  button {
    margin-top: 32px;
  }
}

.notFound {
  padding: 16px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: $text-primary;

  & > .bottom {
    position: absolute;
    bottom: 32px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & > .header {
    position: absolute;
    top: 20px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;

    & > i {
      width: 110px;
      height: 20px;
      mask-image: url("./img/logo.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: $text-primary;
    }
  }
}

:global(.__jivoMobileButton) {
  display: none !important;
}
