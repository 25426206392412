@import "assets/styles/vars";

.button {
  width: 100%;
  border: none;
  background-color:$green;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: $white;
  text-align: center;
}

.blue {
  background: $blue;
  color: $white;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .5px;
  line-height: 20px;
  padding: 16px 12px;
  text-decoration: none;
}

.green {
  background: $green;
  color: $white;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .5px;
  line-height: 20px;
  padding: 16px 12px;
  text-decoration: none;
}

.graySmall {
  padding: 12px;
  background-color: $white;
  border: 1px solid $border-light;
  border-radius: 8px;
  color: $text-primary;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.bottomSticky {
  bottom: 0;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #F3F3F3 38%);
  padding: 28px 16px 16px;
  position: sticky;
  z-index: 2;
}

.greenSticky {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background: $green;
  color: $white;
  border: none;
}

.disabled {
  background-color: $green-light !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $green-light !important;
}

.loader {
  width: 24px;
  height: 24px;
  margin-right: 12px;

  & > div {
    width: 24px;
    height: 24px;
  }

  & > div > div {
    margin: 0;
    width: 24px;
    height: 24px;
    border-width: 4px;
    border-color: $white transparent transparent;
  }
}
