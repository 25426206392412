.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}

.loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #29274F;
		border-radius: 50%;
		animation: load 1.2s cubic-bezier(.5,0,.5,1) infinite;
		border-color: #29274F transparent transparent;
	}

	div:nth-child(1) {
		animation-delay: -.45s;
	}

	div:nth-child(2) {
		animation-delay: -.3s;
	}

	div:nth-child(3) {
		animation-delay: -.15s;
	}

}

@keyframes load {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
