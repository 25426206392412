@import "assets/styles/vars";

.card {
  display: flex;
  flex-direction: column;
  color: $text-primary;
  background-color: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 16px;
}

.description {
  border-top: 1px solid #EBEBEB;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:last-of-type {
    padding-bottom: 20px;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.value {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.sum {
  display: flex;
  color: $white;
  background-color: $green;
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  & > i {
    width: 12px;
    height: 16px;
    margin-right: 8px;
    mask-image: url("./img/ok.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: $white;
  }
}

.empty {
  height: 200px;
  width: 100%;
}
