@import "assets/styles/vars";

.wrapper {
  position: relative;
  padding-top: 28px;
  height: 100vh;
}

.top {
  display: flex;
  width: 100%;
  justify-content: center;
}

.logo {
  width: 103px;
  height: 8px;
  background-size: 103px 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../img/small-logo.svg");
}

.content {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: $dark-blue;
}

.loader {
  width: 124px;
  height: 84px;
  mask-size: 124px 84px;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url("../../img/loader.svg");
  background-color: $dark-blue;
}
