$fontPath: '../fonts/';

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'),
  url($fontPath + 'Montserrat-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat'),
  url($fontPath + 'Montserrat-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat'),
  url($fontPath + 'Montserrat-SemiBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: local('Montserrat'),
  url($fontPath + 'Montserrat-Bold.woff') format('woff');
  font-display: swap;
}

