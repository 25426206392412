@import "src/assets/styles/vars";

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 16px 8px;
  z-index: 5;
}

.bottomInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background-color: $white;
  padding: 6px;
  box-shadow: 0 4px 50px 0 #0000001A;
}

.bottomButtonOrder {
  height: 40px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: $green;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
  text-align: center;
}

.bottomButtonInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 8px;
}

.bottomButtonTotal {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: $dark-blue;

  & > span:first-of-type {
    margin-right: 8px;
  }
}

.bottomButtonAmount {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
  margin-left: 16px;

  & > span:first-of-type {
    margin-right: 8px;
  }
}

.paymentInner {
  display: flex;
  border-radius: 50px;
  background-color: $green;
  padding: 16px;
  box-shadow: 0 4px 50px 0 #0000001A;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;

  & > span {
    width: 100%;
    text-align: center;
  }
}

.paymentInnerCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background-color: $green;
  padding: 16px;
  box-shadow: 0 4px 50px 0 #0000001A;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
}

.productBottomInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background-color: $white;
  padding: 6px;
  box-shadow: 0 4px 50px 0 #0000001A;

  .counter {
    display: flex;
    align-items: center;
    color: $dark-blue;
  }

  .counterBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    background-color: $bg-secondary;
  }

  .counterValue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
  }

  .productBottomAction {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 20px;
    background-color: $green;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: $white;
    gap: 16px;
  }
}
