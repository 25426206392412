@import "assets/styles/vars";

.wrapper {
  bottom: 0;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #F3F3F3 38%);
  padding: 28px 16px 16px;
  position: sticky;
  z-index: 2;
}

.bar {
  position: relative;
  overflow: hidden;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: $blue;
  box-shadow: 0 -10px 20px 0 rgba(40,40,80,.150);
}

.label {
  position: relative;
  z-index: 1;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $green;
}
