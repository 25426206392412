@import "assets/styles/vars";
@import "assets/styles/animations";

.product {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.productImage {
  height: 156px;
  background-color: $white;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

.productDesc {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 16px;
  gap: 4px;
  background-color: $white;
}

.productLabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: $dark-blue;
}

.productDescText {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: $dark-blue;
}

.productCost {
  padding: 16px;
  display: flex;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
}

.productCostNumber {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $dark-blue;
}

.empty {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    border-radius: 16px;
    width: 100%;

    @include empty-animation;
  }
}

.emptyImage {
  height: 156px;
}

.emptyDescription {
  height: 80px;
}

.emptyPrice {
  height: 56px;
}
