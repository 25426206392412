@import "assets/styles/vars";

.pageLoader {
  position: relative;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #F5F5F5 0%, #E4E4E4 100%);
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: progress 1s 1 forwards;
}

.progressOk {
  background: radial-gradient(closest-side, #eee 84%, transparent 86%), conic-gradient($green calc(var(--progress-value) * 1%),$green-light 0);
}

.progressError {
  background: radial-gradient(closest-side, #eee 84%, transparent 86%), conic-gradient($error calc(var(--progress-value) * 1%),$error-light 0);
}

.icon {
  position: relative;
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.ok {
  background-color: $green;

  &::before {
    margin: auto;
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    mask: center url("img/ok.svg") no-repeat;
    background-color: $white;
  }
}

.fail {
  background-color: $error;

  &::before {
    margin: auto;
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    mask: center url("img/fail.svg") no-repeat;
    background-color: $white;
  }
}

.label {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: $text-primary;
}

@property --progress-value {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@keyframes progress {
  to { --progress-value: 100; }
}

.container {
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -5px 50px rgba(0,0,0,.75);
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  position: relative;
  z-index: 2;
  margin-top: auto;
  background: linear-gradient(180deg,#f5f5f5,#e4e4e4);
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding: 16px;
  height: 100%;
}

.errorPage {
  position: relative;
  height: 100vh;
}

.errorContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.errorMessage {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: $text-primary;
  margin-bottom: 24px;
}


