@import "assets/styles/vars";

.inputContainer {
  position: relative;
}

.input {
  height: 48px;
  border: 1px solid $border-gray;
  background: $white;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  color: $dark-blue;

  &:first-of-type {
    margin-top: 16px;
  }

  &:last-of-type {
    margin-top: 8px;
  }

  &::placeholder {
    color: #29274F40;
  }

  &:focus {
    outline: none;
  }
}

.placeholder {
  position: absolute;
  left: 17px;
  top: 24px;
  width: 100%;
  color: #29274F40;
}

.error {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $error;
}
