@import "assets/styles/vars";

.support {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.label {
  margin-bottom: 12px;
  opacity: 0.5;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  color: $dark-blue;
  text-transform: uppercase;
}

.social {
  display: flex;
  gap: 8px;

  & > a {
    width: 50%;
    background-color: $white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  }
}

.links {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: $dark-blue;
    text-decoration: underline;
  }
}
