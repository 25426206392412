@import "assets/styles/vars";

.wrapper {
  background-color: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  padding: 16px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $text-primary;
}

.statusMessage {
  padding: 4px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1px;
  color: $white;
}

.service {
  display: flex;
  margin-top: 10px;
  margin-bottom: 16px;
}

.icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $border-light;
  border-radius: 8px;
  background-color: $white;

  & > svg {
    margin-bottom: 6px;
  }
}

.info {
  margin-left: 14px;
  margin-top: 6px;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-bottom: 7px;
  color: $text-primary;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $text-primary;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
}
