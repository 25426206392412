@import "assets/styles/vars";

.payment {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.items {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 16px;
}

.paymentLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
}

.paymentSum {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  background-color: $white;
}

.paymentSumValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  background-color: $white;
  padding: 16px;
}

.paymentDesc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $dark-blue;
}

.item {
  position: relative;
  display: flex;
  padding: 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-gray;
  }
}

.itemImage {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  margin-right: 12px;
  background-color: $bg-secondary;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.itemInfo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemLabel {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
}

.itemDesc {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $dark-blue;
  opacity: .5;
}

.itemPrice {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
  white-space: nowrap;
}

.paymentSumMethods {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  & > div {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .sbp {
    width: 35px;
    height: 18px;
  }

  .mc {
    width: 23px;
    height: 14px;
  }

  .visa {
    width: 31px;
    height: 10px;
  }

  .mir {
    width: 33px;
    height: 9px;
  }
}

.itemsCategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
}

.itemsCategoryTitle {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: $dark-blue;
  opacity: .5;
}

.itemsCategoryDelete {
  width: 24px;
  height: 24px;
  background-color: $bg-secondary;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentAgreement {
  margin-top: 8px;
}

.itemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.itemCounter {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 20px;
  background-color: $bg-secondary;
}

.itemCount {
  display: flex;
  justify-content: center;
  width: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
}

.itemCounterBtn {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 20px;
    height: 20px;
  }
}
