@import "assets/styles/vars";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  color: $text-primary;
  background-color: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  padding: 16px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 6px;
}

.btn {
  position: sticky;
  bottom: 0;
  z-index: 2;
  width: calc(100% + 32px);
  margin-left: -16px;
}
