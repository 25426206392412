$Roboto: 'Roboto', sans-serif;
$Montserrat: 'Montserrat', sans-serif;
$green: #00D215;
$green-light: #CEEDC2;
$blue: #0731F4;
$white: #FFF;
$error: #FF1616;
$error-light: #F2B8B8;
$border-light: #EBEBEB;
$border-gray: #E7E7E7;
$text-primary: #29274F;
$text-secondary: #908FA3;
$text-gray: #C9C9D3;
$bg-primary: #EFEFEF;
$bg-secondary: #F8F8F8;
$bg-bottom-sheet: #F3F3F3;
$shadow-light: 0 1px 4px 0 rgba(0,0,0,.102);
$dark-blue: #011638;
$gray: #F5F5F5;
