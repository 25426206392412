@import "assets/styles/vars";

.wrapper {
  background-color: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  padding: 16px;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 16px;

  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.block {
  display: flex;
  flex-direction: column;
}

.model {
  font-size: 16px;
  line-height: 20px;
  color: $text-primary;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 8px 0;
}

.number {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: $text-primary;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.option {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text-primary;

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  & dt {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  & dd {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.top {
  position: relative;
  display: flex;
  padding-bottom: 16px;
  margin-bottom: 16px;
  width: 100%;

  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -16px;
    display: block;
    width: calc(100% + 32px);
    height: 1px;
    background-color: $border-light;
  }
}

.sum {
  position: relative;
  display: inline-flex;
  padding: 4px 6px;
  background-color: $green;
  color: $white !important;
  border-radius: 12px;

  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 16px;
    mask: center url("img/ok.svg") no-repeat;
    background-color: $white;
    margin-right: 8px;
  }
}

