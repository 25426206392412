@import "src/assets/styles/fonts";
@import "src/assets/styles/vars";
@import 'css-reset-and-normalize/scss/reset-and-normalize';

*::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

*::-webkit-scrollbar-track {
  height: 4px;
  width: 4px;
  background-color: rgba(0, 0, 0, 0.25);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: calc(4px / 2);
}

button:focus {
  outline: none;
}

html {
  overflow-x: clip;
}

body {
  width: 100vw;
  overflow-x: clip;
  position: relative;
  color: $white;
  font-family: $Montserrat;
  background: $bg-primary;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding-top: 160px;
}
