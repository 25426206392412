@import "assets/styles/vars";

.container {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  background-color: $white;
  border-radius: 12px;
}

.option {
  position: relative;
  padding-left: 30px;

  dt {
    color: $text-primary;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  dd {
    margin-top: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $text-primary;
  }
}

.icon {
  position: absolute;
  top: -4px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
