@import "assets/styles/vars";

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.notActive {
  color: $text-primary;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  margin-bottom: 24px;
  padding-bottom: 20px;
  position: relative;
}
