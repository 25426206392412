@import "assets/styles/vars";
@import "assets/styles/animations";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.categoryTitle {
  margin-bottom: 12px;
  opacity: .5;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  color: $dark-blue;
  text-transform: uppercase;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 8px;
}

.item {
  position: relative;
  height: 160px;
  display: flex;
  align-items: flex-end;
  border-radius: 16px;
  padding: 16px;
  background-color: $white;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  }

  @include card-transition;
}

.itemLabel {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: $white;
  z-index: 2;
}
