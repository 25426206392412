@import "assets/styles/vars";

.wrapper {
  background: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  color: $text-primary;
  text-align: center;
}

.title {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.value {
  font-size: 80px;
  font-weight: 500;
  line-height: 98px;
  letter-spacing: 2px;
}
