@import "assets/styles/vars";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.delivery {
  grid-gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item {
  background: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.title {
  color: $text-primary;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 6px;
}

.time {
  color: $text-primary;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}

.status {
  color: $text-primary;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}
