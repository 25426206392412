@import "assets/styles/vars";

.text {
  color: $text-primary;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.question {
  color: $text-primary;
  margin-top: 54px;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.answers {
  display: flex;
  gap: 16px;
}
