@import "assets/styles/vars";

:global(.stickied) {
  backdrop-filter: blur(6px);
}

.header {
  border-bottom: 1px solid $border-light;
  padding: 16px;
  position: sticky;
  top: -1px;
  transition: padding-top .15s;
  z-index: 2;
}

.title {
  color: $text-primary;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fullTitle {
  white-space: pre-wrap;
}

.description {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: $text-secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
