@import "assets/styles/vars";

.actions {
  display: flex;
  gap: 16px;

  &[data-notvalid="true"] {
    & > button:last-of-type {
      background-color: $green-light;
    }
  }
}
