@mixin card-transition {
  opacity: 0;
  animation: fade-in-bottom 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  $initial-delay: 0s;
  $increase-delay: .1s;
  $total-cards: 6;

  @for $i from 1 through $total-cards {
    &:nth-child(#{$i}) {
      $zero-i: $i - 1;
      animation-delay: #{ $initial-delay + $zero-i * $increase-delay };
    }
  }

  @keyframes fade-in-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@mixin empty-animation {
  background: linear-gradient(
          90deg,
          rgba(236, 236, 236, 0) 40%,
          rgba(236, 236, 236, .5) 50%,
          rgba(236, 236, 236, 0) 60%
  ) $white;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s loading ease-in-out infinite;

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
