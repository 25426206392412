@import "assets/styles/vars";

:global {
  .react-modal-sheet-container {
    background-color: $bg-bottom-sheet !important;

    &[data-scrollable="true"] {
      height: 580px !important;
    }
  }

  .react-modal-sheet-content {
    padding: 20px 16px;
  }

  .react-modal-sheet-backdrop {
    background-color: rgb(0, 0, 0, .7) !important;
    backdrop-filter: blur(6px);
  }
}

.header {
  position: relative;
  height: 64px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $border-light;
}

.line {
  position: absolute;
  top: -16px;
  width: 36px;
  height: 4px;
  border-radius: 2px;
  background-color: $border-gray;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: $text-primary;
  text-align: center;
}

.done {
  display: flex;
  justify-content: right;
  width: 100%;
}

.btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $white;
}

.scrollableContent {
  padding: 20px 16px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: $text-secondary;
  margin-top: 8px;
}
