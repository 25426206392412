.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #29274F;
  margin-bottom: 6px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #29274F;
}

.list {
  list-style: decimal;
  padding-left: 16px;
  margin-top: 8px;

  li {
    margin-bottom: 4px;
    color: #29274F;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.innerList {
  list-style: none;
  padding-left: 16px;
  margin-top: 8px;

  li {
    margin-bottom: 4px;
    color: #29274F;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    a {
      color: #29274F;
    }
  }
}
