@import "assets/styles/vars";

.wrapper {
  background-color: $bg-secondary;
  border-radius: 12px;
  box-shadow: $shadow-light;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.services {
  align-items: center;
  display: flex;
  gap: 16px;
}

.service {
  position: relative;
  width: 100%;
  height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 12px;
  background-color: $white;
  border: 1px solid $border-light;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: $text-primary;
}

.title {
  margin-top: 12px;
  text-align: center;
}

.soon {
  background: $text-primary;
  border-radius: 10px;
  color: $white;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 11px;
  padding: 4px 6px;
  position: absolute;
  right: 6px;
  text-transform: uppercase;
  top: 6px;
}
