@import "assets/styles/vars";

.card {
  border-radius: 12px;
  background: $bg-secondary;
  box-shadow: $shadow-light;
  color: $text-primary;

  &[data-nonactive="true"] button {
    opacity: .5;
  }
}

.image {
  border-radius: 12px 12px 0 0;
  height: 132px;
}

.content {
  padding: 14px 16px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-primary;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 4px;
}

.btn {
  margin-top: 12px;
}
