@import "assets/styles/vars";
@import "assets/styles/animations";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabs {
  margin: 0 -16px;
  width: calc(100% + 32px) !important;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    background-color: $white;
  }

  & > div[data-active="true"] {
    background-color: $dark-blue;
  }

  & > div:first-of-type {
    margin-left: 16px;
  }

  & > div:last-of-type {
    margin-right: 16px;
  }
}

.categoryTitle {
  margin-bottom: 12px;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 8px;
}

.item {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $white;
}

.itemInner {
  display: block;
  padding: 0 8px 8px;
}

.itemImage {
  border-radius: 8px;
  height: 156px;
  background-color: $text-gray;
  background-size: cover;
  background-repeat: no-repeat;
}

.itemLabel {
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
  text-align: center;
}

.itemBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 12px;
  padding: 10px 14px;
  background-color: $dark-blue;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: $white;
}

.itemProductBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 12px;
  padding: 10px 14px;
  background-color: $dark-blue;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: $white;

  & > span {
    margin-right: 8px;
  }

  & > div {
    width: 100%;
    text-align: center;
  }
}

.itemProductBtnCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: $white;
  background-color: $bg-secondary;
  border-radius: 12px;

  .btnCount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 12px;
  }

  .btnAmount {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #011638;
  }
}

.emptyItem {
  display: flex;
  border-radius: 16px;
  width: 100%;
  height: 270px;

  @include empty-animation;
}




